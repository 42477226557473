import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';

import { colors } from '../styles/colors';


const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }  
`;


const About: React.FunctionComponent = () => (
  <IndexLayout>
    <Helmet>
      <title>About</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header css={[outer, SiteHeader]}>
        <div css={inner}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader>
            <PostFullTitle>About</PostFullTitle>
          </PostFullHeader>

          <PostFullContent className="post-full-content">
            <div className="post-content">
              <p>
              I'm a Backend Engineer, Open Source Enthusiast, love building something beautiful, 
              especially using Python, Django, Rails, PostgreSQL and MongoDB.<p/>

              <p>Want to ask something? send me an email at <a href="mailto:hello@notnoob.com">hello@notnoob.com</a></p>
              {/* <p>Curious about my tech stack? See the <a href="https://stackshare.io/notnoob/notnoob/">detail here</a> and <a href="https://stackshare.io/iStockID/iStock/">here</a>.</p> */}
              <b>I prefer to work with :</b>
              </p>
              <blockquote>
                <ul>
                  <li>Python</li>
                  <li>Django & DRF</li>
                  <li>Flask</li>
                  <li>Ubuntu</li>
                  <li>Git</li>
                  <li>Heroku</li>
                  <li>Google Cloud</li>
                  <li>AWS</li>
                  <li>Nginx</li>
                  <li>Celery and Redis</li>
                  <li>PostgreSQL</li>
                  <li>MongoDB</li>
                  <li>WordPress</li>
                  <li>JavaScript</li>
                  <li>jQuery</li>
                  <li>Bootstrap</li>
                  <li>CSS/HTML</li>
                </ul>
                <ul> <b>and prefer not to work with :</b>
                  
                  <li>Objective-C</li>
                  <li><strong>PHP</strong></li>
                  <li>C++</li>
                </ul>
                <ul> <b>I also want to learn with :</b>
                  <li><b>Rust</b></li>
                  <li>NodeJS</li>
                  <li>Agile / Scrum</li>
                  <li>Swift</li>
                  <li>Go</li>
                </ul>

              </blockquote>
              <p>
                Feel free to send me an <a href="mailto:hello@notnoob.com">email</a><p/>
                You can also find me at <a href="https://github.com/iColdPlayer">GitHub</a> and my <a href="https://linkedin.com/company/notnoob">LinkedIn</a>

              </p>
            </div>
          </PostFullContent>
        </article>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;